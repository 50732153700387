<template>
  <div class="locationReports container">

	<p class="h6 text-center text-primary"><b>{{ location }} Inventory Report</b></p>    
	<br>
	<p class="text-danger" align="left">{{alert}}</p>

	<CCard :color="'secondary'">
		<CCardBody>
			<form class="form-inline justify-content-center">
				<div class="form-group">
					<label class="font-weight-bold mr-1" for="fromdate">From:</label>
					<datepicker :disabled-dates="disabledDates" class="pr-2" v-model="fromdate" name="fromdate"></datepicker>

					<label class="font-weight-bold mr-1" for="todate">To:</label>
					<datepicker :disabled-dates="disabledDates" class="pr-4" v-model="todate" name="todate"></datepicker>

					<select v-model="timescale" class="form-control form-control-sm  mr-4">
						<option selected value="Aggregate">Aggregate</option>
						<option value="Daily">Daily</option>
						<option value="Weekly">Weekly</option>
						<option value="Monthly">Monthly</option>
					</select>

					<CButton color="dark" v-on:click.prevent="serverCall('getLocationReportData')">Fetch Data</CButton>
				</div>

				<div>
					<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
					<scale-loader :loading="loading"></scale-loader>
				</div>

			</form>	
		</CCardBody>
	</CCard>

	<br>	

	<ul class="nav nav-tabs">
		<li class="nav-item">
			<a class="nav-link" v-bind:class="{ active: (activeTab === 'In') }" href="#" @click="showTab('In')">Incoming Items</a>
		</li>
		<li class="nav-item">
			<a class="nav-link" v-bind:class="{ active: (activeTab === 'Out') }" href="#" @click="showTab('Out')">Outgoing Items</a>
		</li>
		<li class="nav-item">
			<a class="nav-link" v-bind:class="{ active: (activeTab === 'Closing') }" href="#" @click="showTab('Closing')">Closing Balances</a>
		</li>
	</ul>

	<br>

	<div>
		<div class="btn-group">
			<button type="button" class="btn btn-secondary  mr-2" 
				v-on:click.prevent="$refs.reportTable.expandAll()">Expand All</button>
			<button type="button" class="btn btn-secondary" 
				v-on:click.prevent="$refs.reportTable.collapseAll()">Collapse All</button>
		</div>
		
		<br>
		<div v-if="totalAmt!=0" class="text-right"><b>Total Amount: {{totalAmt}}</b>&nbsp;&nbsp;</div>
		<br>

		<vue-good-table
			ref="reportTable"
			:columns="columns"
			:rows="rows"
			:group-options="{
				enabled: true,
   				collapsable: true
			}"
			:sort-options="{
				enabled: false,
			}"
			styleClass="vgt-table condensed"
		/>
	</div>

	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker';
import ScaleLoader from "@/views/utils/ScaleLoader";
import { firebaseAuth } from '../../firebaseConfig.js';
import Helper from "../utils/Helper.vue"

export default {
	name: 'inventoryEntry',

	mixins: [
		Helper
	],
	
    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {

		return {
			orgId: null,

			alert: '',

			columns: [],
			columnsIn: [],
			columnsOut: [],
			columnsClosing: [],

			rows: [],
			dataList: [],
			totalAmt: 0,
			totalInAmt: 0,
			totalOutAmt: 0,
			totalCloseAmt: 0,

			location: this.$route.params.location,
			fromdate: null,
			todate: null,
			timescale: "Aggregate",

			disabledDates: {
				//to: new Date(2016, 0, 5), // Disable all dates up to specific date
				from: new Date(), // Disable all dates after specific date
			},

			activeTab: 'In',

			loading: false,
		
		};
	},

	computed: {
	},

	mounted(){
		// todate = current date
		this.orgId = this.$route.params['orgId']
		this.fromdate = new Date()
		this.todate = new Date()
		this.location = this.$route.params['location']
		//this.serverCall('getLocationReportData')
	},

	methods: {
		showTab(activeTab){
			this.activeTab = activeTab

			if (activeTab=="In"){
				this.totalAmt = this.totalInAmt
				this.columns = this.columnsIn
			}
			else if (activeTab=="Out"){
				this.totalAmt = this.totalOutAmt
				this.columns = this.columnsOut
			}
			else if (activeTab=="Closing"){
				this.totalAmt = this.totalCloseAmt
				this.columns = this.columnsClosing
			}
		}, 

        serverCall(name) {
            var self = this

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getLocationReportData":
								self.getLocationReportData(idToken)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})
		},
		

        // Get Report Data for the given dates.
        getLocationReportData(authToken) {
            var self = this

            if (!this.fromdate || !this.todate || !this.location){
                self.alert = "Either fromdate or todate or location not specified."
                return
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Inventory..."

			var postJson = {
				"location": this.location,
				"fromDate": this.getMidDay(this.fromdate),
				"toDate": this.getMidDay(this.todate),
				"timescale": this.timescale
			}

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetLocationReportData/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("getLocationReportData - error:" + response.data.err)
					}
					else {
						self.columnsIn = response.data.columnsIn
						self.columnsOut = response.data.columnsOut
						self.columnsClosing = response.data.columnsClosing
						self.rows = response.data.dataList
						self.totalInAmt = response.data.totalInAmt
						self.totalOutAmt = response.data.totalOutAmt
						self.totalCloseAmt = response.data.totalCloseAmt
						// Redraw tabs with new data
						self.showTab(self.activeTab)
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},

		downloadReport() {
			if (this.reportData==[]){
				alert("No report data.")
				return
			}
			let blob = new Blob([this.base64toBlob(this.reportData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
			FileSaver.saveAs(blob, this.reportLongName + '.xlsx');
		},

	},

}
</script>

<style scoped>
.active {
  color : green;
}
</style>

